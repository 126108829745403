



































































































































































































































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { PageBase } from "@/core/models/shared";
import { RegistroOperacionalService } from '@/core/services/operacional';
import { EmpresaService } from '@/core/services/geral';
import { Empresa } from '@/core/models/geral';
import { AlertSimpleErr } from '@/core/services/shared/AlertService';

@Component
export default class DetalharRegistroOperacional extends PageBase { 
    
    service = new RegistroOperacionalService();
    empresaService = new EmpresaService();
    empresa: Empresa = new Empresa();

    overlay: boolean = false;
    id: number = 0;
    selectRadio: number = 0;

    item: any = {};

    mounted() {
        this.id = Number(this.$route.params.id);
        this.Carregar();
    }

    Carregar(){
        this.overlay = true;
        this.service.BoletimMedicao(this.id).then(
            res => {
                this.item = res.data;
                this.item.ternos = this.item.ternos.sort(function(a,b){
                    if(a.nome < b.nome){
                        return -1;
                    }
                    if(a.nome > b.nome){
                        return 1;
                    }
                    return 0;
                });
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.overlay = false;
        })
    }

}
